import ResponsiveAppBar from "../components/AppBar";

import * as React from 'react';
import useCallDataApi from "../hooks/data";
import {useContext, useEffect, useState} from "react";
import {
    Box, Button,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import Footer from "../components/Footer";
import {useHistory, useParams} from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {thousandSeparator} from "../utils/utils";
import Loading from "../components/Loading";
import {HashLink} from "react-router-hash-link";
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import {ReservationProvider} from "../context/ReservationContext";
import ReserveBase from "./reserve/ReserveBase";
import ApartmentContext from "../context/ApartmentContext";
import ConfigContext from "../context/ConfigContext";


const ReserveForHouse = () => {
    const params = useParams()
    const theme = useTheme()
    const [featured, setFeatured] = useState([])
    const [infos, setInfos] = useState([])

    const [loading, setLoading] = useState(true)
    const {getData: fetchInfos} = useCallDataApi('infos')
    const {getData: fetchPeriods} = useCallDataApi('periods')

    const {getApartment} = useContext(ApartmentContext)
    const {baseUrl} = useContext(ConfigContext)
    const history = useHistory()

    useEffect(() => {
        const getData = async () => {
            const [p, i] = await Promise.all([
                fetchPeriods(),
                fetchInfos(`get_for_apartment/?apartment=${params?.id}`),
            ])
            if (p) setFeatured(p.filter(f => f.apartment == params?.id))
            if (i) setInfos(i)
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const apartment = getApartment(parseInt(params?.id))

    document.title = `${apartment?.name} foglalása`

    return <>
        <ResponsiveAppBar/>
        <div className="main">
            <Typography variant='headline1'>{apartment?.name} foglalása</Typography>
            <Typography variant='body1' marginBottom={4}>{apartment?.description}</Typography>
            <div style={{position: 'relative'}}>
                <img src={apartment?.image} style={{width: '100%', marginBottom: '20px'}} alt=""/>
                <Button style={{background: '#fff', color: theme.palette.primary[600], position: 'absolute', left: '50%', bottom: '50px', transform: 'translate(-50%, 0)'}} onClick={() => history.push('/gallery')} variant='contained' endIcon={<NavigateNextRoundedIcon/>}>Megnézem a többi képet is</Button>
            </div>


            {infos.length !== 0 && <Box className='apartment-card' marginBottom={3} padding={1} style={{display: 'flex', flexDirection: 'column', background: theme.palette.primary[20]}}>
                <Typography variant='headline1'>Miket találsz nálunk</Typography>
                <Stack spacing={2} padding={2}>
                    {infos?.map((u, i) => {
                        return <Stack key={u?.id} spacing={2} direction='row' alignItems='center' sx={{width: '100%'}}>
                            <img src={baseUrl + u?.image} width='50px' height='50px' alt=""/>
                            <Typography variant='body2'>{u?.text}</Typography>
                        </Stack>
                    })}
                </Stack>
            </Box>}
            <Typography variant='headline1'>Foglalási feltételek</Typography>
            <Typography variant='body1' marginBottom={4}>Apartmanjainkba minimum 2 éjszaka foglalása lehetséges
                A foglalások között megmaradó helyek 1 éjszakára felár ellenében is foglalhatóak.
                <HashLink to='/#contact'> Személyre szabott ajánlatért keress minket.</HashLink>
            </Typography>
            <Typography variant='headline1'>Fizetési feltételek</Typography>
            <Typography variant='body1' marginBottom={4}>A foglalás véglegesítéséhez a teljes összeget szükséges előre rendezni.
                Ezt a weboldalon keresztül van lehetőség SimplePay-en keresztül is fizetni, vagy a foglalást követő 3 napon belül banki átutalással vagy OTP Szép-kártyával történő átutalással.
                Amennyiben utalást vagy OTP Szépkártyás fizetést választasz, vedd fel velünk a kapcsolatot.
            </Typography>
            <Typography variant='headline1'>SZÉP-kártya</Typography>
            <Typography variant='body1' marginBottom={4}>Vendégházunk OTP SZÉP kártya elfogadóhely. A szállás díját az <a target='_blank' href="https://magan.szepkartya.otpportalok.hu/elfogadohelyek/adatlap/368073/BeLaWood%20/">OTP SZÉP-kártya felületén</a> a vendégház nevének (BeLaWood) megadásával tudod elutalni.
            </Typography>
            <Typography variant='headline1'>Lemondási feltételek</Typography>
            <Typography variant='body1' marginBottom={4}>A teljes összeg kifizetését követő lemondás esetén nem áll módunkban visszautalni azt. Egyedi esetekben, előre leegyeztetve, 
            ezt az összeget egyszer módosított időpontban fel lehet használni. (1 éven belül, esetleges árváltozás esetén a különbözet megfizetése szükséges.)
            </Typography>

            {featured.length !== 0 && <>
                <Typography variant='headline1'>Kiemelt időszakok</Typography>
                <Typography variant='body1' marginBottom={4}>Kiemelt időszakokban egyedi foglalási szabály érvényes.
                    Kérjük vegyétek figyelembe, hogy a kiemelt időszakokban eltérő lehet a minimum éjszakák száma, illetve az ár is változhat.
                </Typography>


                <TableContainer component={Paper} style={{marginBottom: '20px'}}>
                    <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Név</TableCell>
                                <TableCell align="center">Apartman</TableCell>
                                <TableCell align="center">Kezdete</TableCell>
                                <TableCell align="center">Vége</TableCell>
                                <TableCell align="center">Minimum foglalható éjszakák száma</TableCell>
                                <TableCell align="center">Ár</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {featured?.map((row) => (
                                <TableRow
                                    key={row?.name}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell align="center">{row?.name}</TableCell>
                                    <TableCell align="center">{getApartment(row?.apartment)?.name}</TableCell>
                                    <TableCell align="center">{row?.start_date}</TableCell>
                                    <TableCell align="center">{row?.end_date}</TableCell>
                                    <TableCell align="center">{row?.min_days}</TableCell>
                                    <TableCell align="center">{thousandSeparator(row.price)} Ft / éj</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>}

            <ReservationProvider apartment={apartment} featuredPeriods={featured}>
                <ReserveBase/>
            </ReservationProvider>
        </div>

        <Footer/>
        <Loading isLoading={loading}/>
    </>
}

export default ReserveForHouse