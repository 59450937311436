import ResponsiveAppBar from "../components/AppBar";
import {Button, Stack, Typography, useTheme} from "@mui/material";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import {useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";
import {useHistory} from "react-router-dom";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import {thousandSeparator} from "../utils/utils";
import PaidRoundedIcon from "@mui/icons-material/PaidRounded";

const Houses = () => {
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const [apartments, setApartments] = useState([])
    const {getData} = useCallDataApi('apartments')
    const theme = useTheme()
    document.title = 'Vendégházaink'

    useEffect(() => {
        getData()
            .then(a => setApartments(a))
            .finally(() => setLoading(false))
    }, [])

    return <>
        <ResponsiveAppBar/>
        <div className="main" style={{minHeight: '70vh'}}>
            <Typography variant='headline1'>Apartmanok</Typography>
            <Typography variant='body1' marginBottom={4}>Ismerd meg az apartmanokat és foglald le a számodra megfelelőt!</Typography>
            <div className="apartment-selector">
                {apartments?.map(a => <div key={`apartment_${a.id}`} onClick={() => history.push(`/reserve/${a?.id}`)} className="apartment-selector-box" style={{background: `url('${a.image}')`}}>
                    <Stack spacing={1} className='apartment-selector-box__infos'>
                        <Typography align='right' variant='headline1'>{a.name}</Typography>
                        <Stack spacing={1} direction='row' alignSelf='end' alignItems='center'>
                            <Typography align='right' variant='buttonNormal'>Férőhelyek száma: {a.capacity}</Typography>
                            <PeopleRoundedIcon style={{color: '#523A28'}}/>
                        </Stack>
                        <Stack spacing={1} direction='row' alignSelf='end' alignItems='center'>
                            <Typography align='right' variant='buttonNormal'>Ár: {thousandSeparator(a.price)} Ft/éj</Typography>
                            <PaidRoundedIcon style={{color: '#523A28'}}/>
                        </Stack>
                    </Stack>
                </div>)}
            </div>
        </div>
        <Footer/>
        <Loading isLoading={loading} />
    </>
}

export default Houses