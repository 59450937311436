import {Stack, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useContext} from "react";
import ReservationContext from "../../context/ReservationContext";
import {LicenseInfo, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDateFns} from "@mui/x-date-pickers-pro/AdapterDateFns";
import {hu} from "date-fns/locale";
import {DateRangePicker} from "@mui/x-date-pickers-pro/DateRangePicker";
import {enqueueSnackbar} from "notistack";


const SelectDate = () => {
    const {next, selected, isNotAvailableForReserve, maxDate, getAvailability, featuredPeriods, setSelected} = useContext(ReservationContext)

    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY)

    const isDateValid = (date) => !isNaN(date)

    const hasValidData = dateList => dateList.every(d => d) && dateList.every(d => isDateValid(d))

    const isDateRangeValid = dateList => {
        if (!Array.isArray(dateList) || dateList.length < 2) return false
        const [startDate, endDate] = dateList;
        if (!(startDate instanceof Date) || !(endDate instanceof Date)) return false
        return startDate < endDate
    }

    const isReservationIntersecting = dateList => {
        for (let currentDate = new Date(dateList[0]); currentDate <= dateList[1]; currentDate.setDate(currentDate.getDate() + 1)) {
            if (isNotAvailableForReserve(currentDate)) return false
        }
        return true
    }

    const doesMeetMinDaysRequirement = dateList => {
        for (const period of featuredPeriods) {
            const { start_date, end_date, min_days } = period
            const startDate = new Date(start_date)
            const endDate = new Date(end_date)
            const [selectedStartDate, selectedEndDate] = dateList
            if (isIntersects(selectedStartDate, selectedEndDate, startDate, endDate)) {
                return intersectingDaysCount(selectedStartDate, selectedEndDate, startDate, endDate) >= min_days
            }
        }
        return true
    }

    const isIntersects = (selectedStart, selectedEnd, start, end) => {
        selectedStart = new Date(selectedStart);
        selectedEnd = new Date(selectedEnd);
        start = new Date(start);
        end = new Date(end);
        if (isNaN(selectedStart.getTime()) || isNaN(selectedEnd.getTime()) ||
            isNaN(start.getTime()) || isNaN(end.getTime())) {
            throw new Error('Invalid date format.');
        }
        return (selectedStart <= end && selectedEnd >= start)
    }

    const intersectingDaysCount = (selectedStart, selectedEnd, start, end) => {
        selectedStart = new Date(selectedStart);
        selectedEnd = new Date(selectedEnd);
        start = new Date(start);
        end = new Date(end);
        const latestStart = selectedStart > start ? selectedStart : start;
        const earliestEnd = selectedEnd < end ? selectedEnd : end;
        const oneDay = 24 * 60 * 60 * 1000;
        return Math.round(Math.abs((earliestEnd - latestStart) / oneDay))
    }

    const minTwoDays = dateList => {
        const oneDay = 24 * 60 * 60 * 1000
        return Math.round(Math.abs((dateList[1] - dateList[0]) / oneDay)) >= 2
    }

    const validators = [
        {isValid: isDateRangeValid, message: 'Nem megfelelő dátum!'},
        {isValid: isReservationIntersecting, message: 'Ütközik egy másik foglalással!'},
        {isValid: doesMeetMinDaysRequirement, message: 'Nem teljesül a minimum napok száma a kiemelt időszakban!'},
        {isValid: minTwoDays, message: 'Minimum két éjszakára kell foglalni!'},
    ]

    const handleDateChange = (newValue) => {
        if (hasValidData(newValue)) {
            // check
            for (let validator of validators) {
                if (!validator.isValid(newValue)) {
                    setSelected({...selected, selectedDate: newValue})
                    enqueueSnackbar({message: validator.message, variant: 'error'})
                    return
                }
            }
            next({selectedDate: newValue})
            return
        }
        setSelected({...selected, selectedDate: newValue})
    }

    const handleMonthChange = (newValue) => getAvailability(newValue.getFullYear(), newValue.getMonth() + 1)

    return <div className="data-container">
        <Stack spacing={{xs: 2}}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={hu}
            >
                <DateRangePicker
                    value={selected?.selectedDate || [null, null]}
                    onChange={newValue => handleDateChange(newValue)}
                    shouldDisableDate={isNotAvailableForReserve}
                    disablePast={true}
                    maxDate={maxDate}
                    onMonthChange={newValue => handleMonthChange(newValue)}
                    renderInput={(startProps, endProps) => (
                        <Stack spacing={1} direction='row' sx={{width: '100%'}} alignItems='center'>
                            <Stack spacing={1}>
                                <Typography variant='caption'>Érkezés*</Typography>
                                <TextField required {...startProps} label={null} variant='standard'/>
                            </Stack>
                            <Stack spacing={1}>
                                <Typography variant='caption'>Távozás*</Typography>
                                <TextField required {...endProps} label={null} variant='standard'/>
                            </Stack>
                        </Stack>
                    )}
                />
            </LocalizationProvider>
        </Stack>
    </div>
}
export default SelectDate