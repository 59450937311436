import ResponsiveAppBar from "../components/AppBar";
import {
    Box, Button,
    Card,
    CardContent,
    Divider,
    Grid, IconButton,
    ImageList,
    ImageListItem, Paper,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import {useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";
import Carousel from 'react-material-ui-carousel'
import ImageCard from "../components/ImageCard";
import Footer from "../components/Footer";
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import belaWoodLogo from '../assets/BeLa_wood_logo_black.png'
import belaWoodWhiteLogo from '../assets/BeLa_wood_logo_white.png'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import {thousandSeparator} from "../utils/utils";
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import {useHistory} from "react-router-dom";

const Item = ({buttonText, heading, img}) => {
    const history = useHistory()
    return <Paper sx={{
        background: `url(${img})`,
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <Stack spacing={1} style={{display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'}}>
            <img src={belaWoodWhiteLogo} style={{width: '30%'}} alt=""/>

            <Button onClick={() => history.push('/houses')} size='large' startIcon={<CalendarMonthRoundedIcon/>} variant='contained'>
                {buttonText}
            </Button>
        </Stack>
    </Paper>
}


const Home = () => {
    const [apartments, setApartments] = useState([])
    const [images, setImages] = useState([])
    // const [scrollHeight, setScrollHeight] = useState(0)
    const {getData: fetchApartments} = useCallDataApi('apartments')
    const {getData: fetchImages} = useCallDataApi('image-gallery')
    const theme = useTheme()
    const history = useHistory()

    document.title = 'BeLaWood'

    // const scrollListener = (event) => {
    //     const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    //     setScrollHeight(winScroll)
    // }

    useEffect(() => {
        fetchApartments().then(h => setApartments(h))
        fetchImages().then(i => setImages(i))

        // window.addEventListener("scroll", scrollListener);
    }, [])

    return <>
        <ResponsiveAppBar fade/>
        <div style={{width: '100%', height: '100vh'}}>
            <Carousel navButtonsAlwaysVisible={true} animation='fade' interval={2000} style={{height: '100vh'}}>
                {
                    images.map((item, i) => <Item key={i}
                                                  heading='Foglaljon még ma!'
                                                  img={item?.image}
                                                  buttonText='Árkalkuláció és foglalás'/>)
                }
            </Carousel>
        </div>
        <div className="main">
            <Typography variant='headline1'>Apartmanok</Typography>
            <Typography variant='body1' align='justify' marginBottom={1}>
                Az álmunk az volt, hogy egy olyan helyet teremtsünk, ahol az ember elmenekülhet a szürke hétköznapok elől és ahol kicsit emlékeztetheti magát arra, hogy milyen szép is az élet.
            </Typography>
            <Typography variant='body1' align='justify' marginBottom={1}>
                …és ezt mind úgy szerettük volna megvalósítani, ahogyan azt mi elképzeltük.
            </Typography>
            <Typography variant='body1' align='justify' marginBottom={1}>
                Két picurka, ámde minden igényt kielégítő faház Eger közelében, amiknek célja hogy lakóinak örökre szóló élményeket szerezzenek.
            </Typography>
            <Typography variant='body1' align='justify' marginBottom={1}>
                Ismerd meg vendégházainkat…
            </Typography>
            <Grid container spacing={2} marginTop={2}>
                {apartments?.map((a, i) => <Grid key={`apartment_${i}`} item xs={12} md={12} xl={6}>
                    <div className="apartment-card" style={{background: theme.palette.primary[20]}}>
                        <div className="apartment-card-left-column" style={{background: `url(${a?.image})`}}/>
                        <div className="apartment-card-right-column">
                            <Stack spacing={1}>
                                <Typography align='right' variant='headline1'>{a.name}</Typography>
                                <Stack spacing={1} direction='row' alignSelf='end' alignItems='center'>
                                    <Typography align='right' variant='buttonNormal'>Férőhelyek száma: {a.capacity}</Typography>
                                    <PeopleRoundedIcon/>
                                </Stack>
                                <Stack spacing={1} direction='row' alignSelf='end' alignItems='center'>
                                    <Typography align='right' variant='buttonNormal'>Ár: {thousandSeparator(a.price)} Ft/éj</Typography>
                                    <PaidRoundedIcon/>
                                </Stack>
                                <Typography align='justify' variant='body2'>{a.description}</Typography>
                                <Button variant='outlined' onClick={() => history.push(`/reserve/${a.id}`)}>Tovább</Button>
                            </Stack>
                        </div>
                    </div>
                </Grid>)}
            </Grid>
        </div>
        <Divider sx={{margin: 4}}><img src={belaWoodLogo} style={{width: '60px'}} alt="logo"/></Divider>
        <div className="main">
            <Typography variant='headline1'>Galéria</Typography>
            {/*<Typography variant='body1'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa cupiditate delectus eum fuga nemo pariatur saepe, similique. Corporis dignissimos eveniet fuga nisi odio omnis perferendis provident suscipit vel velit, voluptatibus?</Typography>*/}
            <Grid container spacing={0} sx={{marginBottom: 4}} marginTop={2}>
                {/*{images?.slice(0, 3)?.map(i => <Grid key={i.id} item xs={12} md={6} xl={4}><img style={{width: '100%', height: '600px'}} src={i.image} alt=""/></Grid>)}*/}
                {images?.slice(0, 3)?.map(i =>
                    <Grid key={i.id} item xs={12} md={6} xl={4} style={{
                        height: '500px',
                        background: `url(${i.image})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: "contain",
                        backgroundPosition: "50% 50%"
                    }}>
                        {/*<img style={{width: '100%', height: '600px'}} src={i.image} alt=""/>*/}
                    </Grid>
                )}
            </Grid>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Button onClick={() => history.push('/gallery')} variant='outlined' endIcon={<NavigateNextRoundedIcon/>}>Megnézem a többi képet is</Button>
            </Box>
        </div>
        <Divider sx={{margin: 4}}><img src={belaWoodLogo} style={{width: '60px'}} alt="logo"/></Divider>
        <Grid container sx={{marginTop: 4}} id='contact'>
            <Grid item xs={12} lg={12} xl={6} sx={{padding: 4, background: theme.palette.primary[20]}}>
                <Stack spacing={4}>
                    <Typography variant='headline1'>Kapcsolat</Typography>
                    <Typography variant='body1'>Amennyiben elakadtál, vagy bármilyen kérdésed merülne fel, keress minket nyugodtan az alábbi elérhetőségek egyikén.
                        Megtalálsz minket Facebookon és Instagrammon is.
                    </Typography>
                    <Stack spacing={2} direction='row'><LocationOnRoundedIcon/><Typography sx={{alignSelf: 'center'}}
                                                                                           variant='buttonNormal'>3394
                        Egerszalók, Galamb utca 29</Typography></Stack>
                    <Stack spacing={2} direction='row'><LocalPhoneRoundedIcon/><Typography sx={{alignSelf: 'center'}}
                                                                                           variant='buttonNormal'>+36 30
                        864
                        2606</Typography></Stack>
                    <Stack spacing={2} direction='row'><EmailRoundedIcon/><Typography sx={{alignSelf: 'center'}}
                                                                                      variant='buttonNormal'>belawoodinfo@gmail.com</Typography></Stack>
                </Stack>
                <Stack direction="row" spacing={2} marginTop={4}>
                    <IconButton size='large' onClick={() => window.open('https://www.facebook.com/tinyhouse.belawood', '_blank')}><FacebookOutlinedIcon fontSize={'30'}/></IconButton>
                    <IconButton size='large' onClick={() => window.open('https://www.instagram.com/belawoodtinyhouse', '_blank')}><InstagramIcon fontSize={'30'}/></IconButton>
                    {/*<IconButton size='large' onClick={() => window.open('/', '_blank')}><TwitterIcon fontSize={'30'}/></IconButton>*/}
                    {/*<IconButton size='large' onClick={() => window.open('/', '_blank')}><YouTubeIcon fontSize={'30'}/></IconButton>*/}
                </Stack>
            </Grid>
            <Grid item xs={12} lg={12} xl={6}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3377.254540690782!2d20.332914376864643!3d47.86458947012606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47408d3527f5224f%3A0x8b87758f8b96e1ee!2sEgerszal%C3%B3k%2C%20Galamb%20u.%2029%2C%203394!5e1!3m2!1shu!2shu!4v1684842834657!5m2!1shu!2shu"
                    width="100%" height="600" style={{border: 0}} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"/>
            </Grid>
        </Grid>
        <Footer/>
    </>

}

export default Home
