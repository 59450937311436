import {createContext, useState, useEffect} from "react";
import useCallDataApi from "../hooks/data";
import {format} from "date-fns";
import {useSnackbar} from "notistack";
import Loading from "../components/Loading";


const ReservationContext = createContext();

export default ReservationContext;

export const ReservationProvider = ({apartment, featuredPeriods, children}) => {
    const steps = ['selectedDate', 'personal', 'payment']
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState({apartment: apartment})
    const [activeStep, setActiveStep] = useState(0)
    const [maxDate, setMaxDate] = useState(new Date('3000-12-01'))
    const [availability, setAvailability] = useState({})
    const [personalData, setPersonalData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '+36',
        number_of_guests: 2
    })
    const [billingData, setBillingData] = useState({
        billing_name: '',
        country: '',
        postal_code: '',
        city: '',
        address: '',
        address_2: '',
        payment_type: 'simple',
        accept: false,
    })
    const [price, setPrice] = useState(0)
    const [discount, setDiscount] = useState({})
    const [error, setError] = useState({})
    const {postData} = useCallDataApi('reservations')
    const {postData: postCode} = useCallDataApi('codes')
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        if (selected?.selectedDate?.every(e => e)) {
            const startDate = selected?.selectedDate[0]
            const endDate = selected?.selectedDate[1]
            if (startDate && endDate) calculatePrice(startDate, endDate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected?.selectedDate, selected?.code])

    useEffect(() => {
        if (selected?.apartment) {
            const today = new Date()
            getAvailability(today.getFullYear(), today.getMonth() + 1)
            const selectedApartment = selected?.apartment
            setMaxDate(addDays(selectedApartment?.max_reservation_date))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected?.apartment])

    const addDays = (days) => {
        const result = new Date()
        result.setDate(result.getDate() + days)
        return result
    }

    const paymentTypeMap = {
        simple: 'Simple Pay Online',
        transfer: 'Átutalás',
        beautiful: 'Szép kártya'
    }

    const reserve = () => {
        setLoading(true)
        postData('reserve/', {
            start_date: format(selected?.selectedDate[0], 'yyyy-MM-dd'),
            end_date: format(selected?.selectedDate[1], 'yyyy-MM-dd'),
            apartment: selected?.apartment?.id,
            code: selected?.code || null,
            ...personalData,
            ...billingData
        }).then(r => {
            if (r?.redirect_url) window.location.href = r.redirect_url
            else enqueueSnackbar('Váratlan hiba történt!', {variant: 'error'})
            console.log(r)
        }).finally(() => setLoading(false))
    }

    const isReservationBetween = (startDate, endDate) => {
        for (let day of Object.keys(availability)) {
            if (startDate < day && day < endDate && !availability[day]) {
                setSelected([null, null])
                enqueueSnackbar('Ütközik egy másik foglalással!', {variant: 'error', autoHideDuration: 2000})
                return
            }
        }
    }

    const getCoupon = (code) => {
        setLoading(true)
        postCode('is_valid_code/', {code: code})
            .then(r => {
                if (r?.valid) {
                    setDiscount(r)
                    setSelected({...selected, code: code})
                }
                else enqueueSnackbar('Nincs ilyen kód vagy lejárt!', {variant: 'error', autoHideDuration: 2000})
            })
            .finally(() => setLoading(false))
    }

    const deleteCouponCode = () => {
        setDiscount({})
        setSelected(prevState => {
            const newState = { ...prevState };
            delete newState?.code;
            return newState;
        })
    }

    const isNotAvailableForReserve = (date) => {
        const strDate = format(date, "yyyy-MM-dd")
        if (!Object.keys(availability).includes(strDate)) return true
        return !availability[strDate]
    }

    const isDateValid = (date) => !isNaN(date)
    const hasValidData = dateList => dateList.every(d => d) && dateList.every(d => isDateValid(d))

    const calculatePrice = () => {
        if (!hasValidData(selected?.selectedDate)) return
        setLoading(true)
        const data = {
            apartment: selected?.apartment?.id,
            start_date: format(selected?.selectedDate[0], "yyyy-MM-dd"),
            end_date: format(selected?.selectedDate[1], "yyyy-MM-dd"),
            code: selected?.code
        }
        postData('get_price/', data)
            .then(r => setPrice(r))
            .finally(() => setLoading(false))
    }

    const getAvailability = (y, m) => {
        setLoading(true)
        postData('monthly_availability/', {year: y, month: m, apartment: selected?.apartment?.id || apartment?.id})
            .then(r => setAvailability(r))
            .finally(() => setLoading(false))
    }

    const back = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
        const keyToRemove = steps[activeStep]
        delete selected[keyToRemove]
        if (keyToRemove === 'selectedDate') setPrice({})
    }

    const next = (selectedObj) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setSelected({...selected, ...selectedObj})
    }

    const isBillingFilled = () => billingData?.billing_name && billingData?.city && billingData?.country?.code && billingData?.postal_code && billingData?.address && billingData?.state
    const isPersonalFilled = () => personalData?.first_name && personalData?.last_name && personalData?.email && personalData?.phone?.length > 3
    const isError = () => Object.values(error).every(x => x === false)

    const contextData = {
        steps,
        selected,
        setSelected,
        activeStep,
        back,
        next,
        maxDate,
        getAvailability,
        calculatePrice,
        reserve,
        isReservationBetween,
        getCoupon,
        isNotAvailableForReserve,
        price,
        error,
        setError,
        personalData,
        setPersonalData,
        billingData,
        setBillingData,
        paymentTypeMap,
        isPersonalFilled,
        isBillingFilled,
        isError,
        featuredPeriods,
        deleteCouponCode,
        discount
    }

    return <ReservationContext.Provider value={contextData}>
        {children}
        <Loading isLoading={loading}/>
    </ReservationContext.Provider>
}